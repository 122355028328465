<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="Array.isArray(adminList)">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Administrators ({{ adminList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-list dense nav>
                            <v-list-item v-if="adminList.length === 0">No users yet.</v-list-item>
                            <v-list-item v-for="(item, idx) in adminList" v-bind:key="idx" class="my-2">
                                <v-list-item-content class="text-start">
                                    <!-- TODO: restore link to view user page when that page is implemented -->
                                    <!-- <router-link :to="viewUserLink(item)"> -->
                                        {{ item.user_display_name }}
                                        &lt;{{ item.user_email }}&gt;
                                    <!-- </router-link> -->
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        adminList: null,
        error: null,
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
    },
    methods: {
        async loadAdminList() {
            try {
                this.$store.commit('loading', { loadAdminList: true });
                const response = await this.$client.organization(this.$route.params.organizationId).linkOrganizationUser.search();
                if (response?.list) {
                    this.adminList = response.list;
                } else {
                    this.adminList = null;
                }
            } catch (err) {
                console.error('loadAdminList failed', err);
            } finally {
                this.$store.commit('loading', { loadAdminList: false });
            }
        },
        viewUserLink(item) {
            return { name: 'organization-view-admin', params: { organizationId: this.$route.params.organizationId, userId: item.user_id } };
        },
    },
    mounted() {
        this.loadAdminList();
    },
};
</script>
